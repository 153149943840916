import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { UserOutlined, ProjectOutlined, SearchOutlined, ProfileOutlined  } from '@ant-design/icons';
import './NavBar.css';
const NavBar = () => {
    return (
        <div className="navbar"> {/* 使用自定义样式类名 */}
            <Menu className="navbar-menu" mode="horizontal"> {/* 使用自定义样式类名 */}
                <Menu.Item key="kol" icon={<UserOutlined />} className="navbar-menu-item">
                    <Link to="/kol">Kol</Link>
                </Menu.Item>
                <Menu.Item key="project" icon={<ProjectOutlined />} className="navbar-menu-item">
                    <Link to="/project">Project</Link>
                </Menu.Item>
                <Menu.Item key="search" icon={<SearchOutlined />} className="navbar-menu-item">
                    <Link to="/search">Search</Link>
                </Menu.Item>
                <Menu.Item key="recentfollow" icon={<ProfileOutlined />} className="navbar-menu-item">
                    <Link to="/recentfollow">Recent Project</Link>
                </Menu.Item>
                <Menu.Item key="recentkol" icon={<UserOutlined />} className="navbar-menu-item">
                    <Link to="/recentkol">Recent KOL</Link>
                </Menu.Item>
                <Menu.Item key="5000project" icon={<ProjectOutlined />} className="navbar-menu-item">
                    <Link to="/5000project">5000 Project</Link>
                </Menu.Item>
                <Menu.Item key="5000kol" icon={<UserOutlined />} className="navbar-menu-item">
                    <Link to="/5000kol">5000 KOL</Link>
                </Menu.Item>
            </Menu>
        </div>
    );
};

export default NavBar;
