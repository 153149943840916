import React, { useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';


const Wrapper = styled('div')`
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Input = styled('input')`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled('button')`
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
axios.defaults.withCredentials = true;

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate(); // 用于实现页面跳转

    const handleSubmit = async e => {
        e.preventDefault();

        axios.post('/login', {
            username,
            password
        }).then(response => {
            localStorage.setItem('token', response.data.token);
            message.success('登录成功');
            // 跳转到 /project 页面
            navigate('/project');
        }).catch(err => {
            message.error('登录失败，请检查用户名密码');
        });

        // try {
        //     // 发送 POST 请求到 /login 接口
        //     const response = await axios.post('/login', { username, password });
        //
        //     // 如果请求成功
        //     if (response.data === 'success') {
        //         // 显示成功消息
        //         message.success('登录成功');
        //         // 跳转到 /project 页面
        //         navigate('/project');
        //     } else {
        //         // 如果请求失败，显示失败消息
        //         message.error('登录失败，请检查用户名密码');
        //     }
        // } catch (error) {
        //     // 如果请求出错，显示错误消息
        //     console.error(error);
        //     message.error('登录失败，请检查用户名密码');
        // }
    };

    return (
        <Wrapper>
            <form onSubmit={handleSubmit}>
                <Input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <Button type="submit">Login</Button>
            </form>
        </Wrapper>
    );
};

export default LoginForm;
