import React, {useEffect, useState, useRef, Component} from 'react';
import {Card, Pagination, Row, Col, Avatar, Collapse, Input, Button} from 'antd';
import axios from 'axios';
import { UserOutlined } from "@ant-design/icons";
import { Chart, LineController, CategoryScale, LineElement, PointElement, LinearScale, Tooltip, registerables} from 'chart.js';
import * as echarts from "echarts";
import Linkify from 'react-linkify';
import {useNavigate} from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
Chart.register(...registerables);
Chart.register(LineController, CategoryScale, LineElement, PointElement, LinearScale, Tooltip);
import { Spin } from 'antd';

const pageSize = 999; // 每页显示的项目数量
const { Panel } = Collapse;
Chart.defaults.plugins.tooltip.usePointStyle = true;
axios.defaults.withCredentials = true;

function ZepumpFollowKol() {
    const [projects, setProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProjects, setTotalProjects] = useState(0);
    const chartRefs = useRef([]);
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(false);

    const [avatarUrls, setAvatarUrls] = useState(new Map());
    const navigate = useNavigate(); // 用于实现页面跳转
    const [keywords, setKeywords] = useState('');
    const [searchText, setSearchText] = useState('');

    const data = `这是一段示例文本，你可以在这里搜索关键词。`;

    const handleSearch = () => {
        setSearchText(keywords);
    };
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    // const highlightText = (text, searchText) => {

    //     const keywords = searchText.toLowerCase().split(',');
    //     const regex = new RegExp(`(${keywords.join('|')})`, 'gi');
    //     const parts = text.replace(/@(\w+)/g, '<a href="https://twitter.com/$1">@$1</a>').split(regex);
    //     // const parts = text.split(regex);

    //     return (
    //         <span>
    //   {parts.map((part, i) =>
    //       part.startsWith('@')
    //           ? <a key={i} href={`https://twitter.com/${part.slice(1)}`}>{part}</a>
    //           : keywords.includes(part.toLowerCase())
    //               ? <span key={i} className="highlight">{part}</span>
    //               : part
    //   )}
    // </span>
    //     );
    // };

    const highlightText = (text, searchText) => {
        if(text === '' || text === null) return '';
        const keywords = searchText.toLowerCase().split(',');
        const regex = new RegExp(`(${keywords.join('|')})`, 'gi');
      
        let newText = text.replace(regex, (matched) => `<span class='highlight'>${matched}</span>`);
        
        // 处理twitter链接
        newText = newText.replace(/@(\w+)/g, '<a href="https://twitter.com/$1">@$1</a>');
      
        return newText;
      };


    async function updateAvatarUrls(names_array) {
        let names = names_array[0];
        if(names === undefined) {
            return;
        }
        console.log("names: ", names);
        const avatarResp = await axios.get(`/kol_avatar?names=${names}`);
        for (let i = 0; i < names.split(",").length; i++) {
            let name = names.split(",")[i];
            if (avatarUrls.has(name)) {
                continue;
            }
            let avatar_url = avatarResp.data.data[i];
            setAvatarUrls(prevMap => {
                const newMap = new Map(prevMap);
                newMap.set(name, avatar_url);
                return newMap;
            });
        }
    }

    useEffect(() => {
        fetchProjects();
    }, [currentPage]);

    useEffect(() => {
        const interval = setInterval(() => {
            window.location.reload();
        }, 3600 * 1000); // 每小时刷新一次

        // 组件卸载时清除定时器
        return () => {
            clearInterval(interval);
        };
    }, []);

    const fetchProjects = async () => {
        setLoading(true);
        await axios.get('/fullpeople?is_zepump_follow=True', {
            params: {
                offset: (currentPage - 1) * pageSize,
                limit: pageSize,
            },
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => {
            // console.log('&&&&&', response.status);
            if(response.status !== 200) {
                navigate('/login');
            }
            const { data, count } = response.data;
            setProjects(data);
            setTotalProjects(count);
        }).catch((error) => {
            // console.log(error)
            navigate('/login');
        });
            
        setLoading(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    const getLabelForLastTwoWeeks = () => {
        const labels = [];
        const currentDate = new Date();
        for (let i = 13; i >= 0; i--) {
            const date = new Date(currentDate.getTime() - i * 24 * 60 * 60 * 1000);
            labels.push(date.toLocaleString('default', { month: 'short', day: 'numeric' }));
        }
        return labels;
    };

    const getLabelForLastSixHours = (follower_count_six_hours) => {
        const labels = [];
        const currentDate = new Date();
        for (let i = follower_count_six_hours.split(',').length; i >= 1; i--) {
            const hoursAgo = i * 4;
            labels.push(`${hoursAgo}h `);
        }
        return labels;
    };
    function formatDate(dateString) {
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const day = dateObj.getDate();
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();
        const seconds = dateObj.getSeconds();

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    function getLabelForPrices(prices) {
        const priceArray = JSON.parse(prices);
        const labels = [];
        for(let i = 0; i < priceArray.length; i++) {
            if(i % 5 === 0) {
                labels.push(new Date(priceArray[i][0]).toLocaleString().replace('GMT+0800 (中国标准时间)', ''));
                // console.log('time: ', priceArray[i][0]);
            }
        }
        // console.log('labels', labels);
        return labels;
    }

    function getDataForPrices(prices) {
        const priceArray = JSON.parse(prices);
        const data = [];
        for(let i = 0; i < priceArray.length; i++) {
            if(i % 5 == 0) {
                data.push(Number.parseFloat(priceArray[i][1].toExponential()));
            }
        }
        // console.log('data', data);
        return data;
    }

    function calcGrowthRate(str) {
        // 将输入的字符串分割为一个数组
        let numArray = str.split(",").map(Number);

        // 创建一个新数组来存储每个数字的增长百分比
        let growthRateArray = [0];

        // 遍历数字数组，计算每个数字的增长百分比
        for (let i = 1; i < numArray.length; i++) {
            let growthRate = ((numArray[i] - numArray[i - 1]) * 1000 / numArray[i - 1]);
            growthRateArray.push(growthRate);
        }
        // console.log(growthRateArray);
        // 返回增长百分比数组
        return growthRateArray;
    }

    function notAllZeroes(str) {
        let bits = str.split(',');
        for(let i = 0; i < bits.length; i++) {
            if(bits[i] !== '0' ) {
                console.log("not all zeros");
                return true;
            }
        }
        console.log("all zeroes")
        return false;
    }

    const isAllZero = ((arr) => {
        let array = arr.split(",");
        console.log("*****", array);
        for(let i = 0; i < array.length; i++) {
            if(arr[i] !== 0 ) {
                console.log("false");
                return false;
            }
        }
        return true;
    })

    const getTwitterDesc = ((text) => {
        return text.replace(/@(\w+)/g, '<a href="https://twitter.com/$1">@$1</a>');
    })

    return (

        <div>
            <div className="App">
                <Input
                    placeholder="输入关键词，以逗号分隔"
                    value={keywords}
                    onChange={e => setKeywords(e.target.value)}
                    style={{ width: '300px', marginRight: '10px' }}
                />
                <Button type="primary" onClick={handleSearch}>搜索</Button>
            </div>
            {loading? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Spin />
                </div>
            ) : (
                            <Row gutter={[16, 16]}>
                            {projects.map((project, index) => (
                                <Col span={windowWidth < 800 ? 24 : 8} key={project.id}>
                                    <Card style={{ height: '100%', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                        <Card.Meta
                                            avatar={<Avatar src={project.avatar_url} alt="Avatar" icon={<UserOutlined />} />}
                                            title={
                                                <a href={`https://twitter.com/${project.username}`} target="_blank" rel="noopener noreferrer">
                                                    {<div dangerouslySetInnerHTML={{__html: highlightText(project.username, searchText)}} />}
                                                </a>
                                            }
                                            // description= {<p dangerouslySetInnerHTML={{ __html: ReactDOMServer.renderToString(highlightText(project.description, searchText)) }} /> }
                                            // description={<p dangerouslySetInnerHTML={{ __html:highlightText(project.description, searchText) }} />}
                                            // description={<p dangerouslySetInnerHTML={{ __html:getTwitterDesc(project.description) }} />}
                                            // description={
                                            //     <p>{highlightText(project.description, searchText)}</p>
                                            // }
                                            description={<div dangerouslySetInnerHTML={{__html: highlightText(project.description, searchText)}} />}
            
                                        />
                                        
                                        <div>Follower Count: {project.follower_count}</div>
                                        <div>Follow Kol Total Count: {project.total}</div>
                                        
            
                                        <Collapse onChange={updateAvatarUrls}>
                                            <Panel header={`Recent KOL Follows: ${project.names.split(',').length}`} key={project.names}>
                                                {project.names.split(',').map((name, i) => (
                                                    <div key={i} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                                        <a href={`https://twitter.com/${name}`} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img style={{ width: 50, height: 50, objectFit: 'cover', marginRight: '10px' }} src={avatarUrls.get(name)} alt="用户头像" />
                                                            <div>{name}</div>
                                                        </a>
                                                        <div>{project.times.split(',')[i]}</div>
                                                    </div>
                                                ))}
                                            </Panel>
                                        </Collapse>
            
            
            
                                    </Card>
                                </Col>
                            ))}
                        </Row>
            )}


            <div style={{ marginTop: '16px', textAlign: 'right' }}>
                <Pagination current={currentPage} pageSize={pageSize} total={totalProjects} onChange={handlePageChange} />
            </div>
        </div>
    );
}

export default ZepumpFollowKol;
